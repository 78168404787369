import "%modules%/header/header";
import "%modules%/footer/footer";

import "%modules%/home/main-home";
import "%modules%/sobre-nos/about-us";
import "%modules%/servicos/services";
import "%modules%/estrutura/structure";
import "%modules%/colaboradores/employees";
import "%modules%/doacoes/doacoes";
import "%modules%/fale-conosco/contact";
