document.addEventListener("DOMContentLoaded", function () {
    const body = document.body;
    const isMobileDevice = window.innerWidth <= 768;
    const btnMobile = document.getElementById("btn-mobile");
    const menuItems = document.querySelectorAll(".wc-header__menu--nav_item");

    function toggleMenu(event) {
        if (event.type === "touchstart") event.preventDefault();
        const headerNav = document.getElementById("header-nav");
        headerNav.classList.toggle("active");
        const active = headerNav.classList.contains("active");
        event.currentTarget.setAttribute("aria-expanded", active);
        if (active) {
            event.currentTarget.setAttribute("aria-label", "Fechar Menu");
            body.classList.add("overflow-hidden");
            btnMobile.classList.add("active");
        } else {
            event.currentTarget.setAttribute("aria-label", "Abrir Menu");
            body.classList.remove("overflow-hidden");
            btnMobile.classList.remove("active");
        }
    }

    function closeMenuOnOptionSelect() {
        const headerNav = document.getElementById("header-nav");
        headerNav.classList.remove("active");
        btnMobile.classList.add("active");
        btnMobile.setAttribute("aria-expanded", false);
        btnMobile.setAttribute("aria-label", "Abrir Menu");
        body.classList.remove("overflow-hidden");
    }

    menuItems.forEach((menuItem) => {
        menuItem.addEventListener("click", closeMenuOnOptionSelect);
        menuItem.addEventListener("touchstart", closeMenuOnOptionSelect);
    });

    btnMobile.addEventListener("click", toggleMenu);
    btnMobile.addEventListener("touchstart", toggleMenu);

    if (isMobileDevice) {
        const dropdownArray = Array.from(
            document.querySelectorAll(".dropdown")
        );

        dropdownArray.forEach(function (el) {
            const button = el.querySelector('a[data-toggle="dropdown"]'),
                menu = el.querySelector(".dropdown-menu"),
                arrow = button.querySelector("i.icon-arrow");

            button.onclick = function (event) {
                if (!menu.classList.contains("show")) {
                    // Fechar todos os dropdowns abertos
                    dropdownArray.forEach(function (otherDropdown) {
                        const otherMenu =
                            otherDropdown.querySelector(".dropdown-menu");
                        const otherArrow =
                            otherDropdown.querySelector("i.icon-arrow");
                        if (
                            otherMenu !== menu &&
                            otherMenu.classList.contains("show")
                        ) {
                            otherMenu.classList.remove("show");
                            otherMenu.classList.add("hide");
                            otherArrow.classList.remove("open");
                            otherArrow.classList.add("close");
                        }
                    });

                    menu.classList.add("show");
                    menu.classList.remove("hide");
                    arrow.classList.add("open");
                    arrow.classList.remove("close");
                    event.preventDefault();
                } else {
                    menu.classList.remove("show");
                    menu.classList.add("hide");
                    arrow.classList.remove("open");
                    arrow.classList.add("close");
                    event.preventDefault();
                }
            };
        });
    }
    $(window).scroll(function () {
        var sticky = $(".header__container"),
            scroll = $(window).scrollTop();

        if (scroll >= 100) sticky.addClass("is-fixed");
        else sticky.removeClass("is-fixed");
    });
});
