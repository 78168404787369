document.addEventListener("DOMContentLoaded", function () {
    const copyLinkBtn = document.getElementById("copy-link-btn");
    const qrcodeLink = document.getElementById("qrcode-link");

    copyLinkBtn.addEventListener("click", function () {
        // Create a range object
        const range = document.createRange();
        // Select the text inside the qrcode link paragraph
        range.selectNode(qrcodeLink);
        // Add the selected text to the clipboard
        window.getSelection().addRange(range);

        try {
            // Copy the selected text
            document.execCommand("copy");
            copyLinkBtn.textContent = "✓ Link copiado!";
        } catch (err) {
            console.error("Erro ao copiar link:", err);
            copyLinkBtn.textContent = "✕ Erro ao copiar link.";
        }

        // Remove the selection
        window.getSelection().removeAllRanges();
    });
});
