$(document).ready(function () {
    $(".about-team__list").slick({
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    });
});
$(document).ready(function () {
    $("#yearSelect").change(function () {
        const selectedYearNumber = $(this).val();
        if (selectedYearNumber === "2023") {
            $("#numbers-table-last").show();
            $("#numbers-table-last_before").hide();
        } else if (selectedYearNumber === "2022") {
            $("#numbers-table-last").hide();
            $("#numbers-table-last_before").show();
        }
    });
});
