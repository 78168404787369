$(document).ready(function () {
    $(".structure__slick").slick({
        lazyLoad: "ondemand",
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        initialSlide: 0,
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    vertical: false,
                    verticalSwiping: false,
                },
            },
        ],
    });
});
