import $ from "jquery";
import "slick-carousel";

// Garantindo que o jQuery seja fornecido globalmente
window.$ = $;
window.jQuery = $;

$(document).ready(function () {
    $(".home-main__banner--container").slick({
        dots: true,
        autoplay: true,
        autoplaySpeed: 8000,
        // fade: true,
        // cssEase: "linear",
    });
});
$(document).ready(function () {
    $(".home-employees__list").slick({
        dots: false,
        arrows: false,
        infinite: true,
        swipe: true,
        swipeToSlide: true,
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToShow: 6,
        slidesToScroll: 1,
        cssEase: "ease",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    });
});
