$(document).ready(function () {
    $(".employees-items__list").slick({
        dots: false,
        arrows: true,
        infinite: false,
        swipe: true,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    });
});
